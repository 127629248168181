/**
Basic Table Styles
 */
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

table {
    border: 1px solid #efefef;
}

table.simple {
    width: 100%;
    border: none;
    border-spacing: 0;
    text-align: left;
}

table thead tr th.MuiTableCell-root {
    background-color: #cfcfcf;
}

table.simple thead tr th {
    padding: 16px 8px;
    font-weight: 500;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    white-space: nowrap;
}

table.simple thead tr th:first-child {
    padding-left: 24px;
}

table.simple thead tr th:last-child {
    padding-right: 24px;
}

table.simple tbody tr td {
    padding: 12px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table.simple tbody tr td:first-child {
    padding-left: 24px;
}

table.simple tbody tr td:last-child {
    padding-right: 24px;
}

table.simple tbody tr:last-child td {
    border-bottom: none;
}

table.simple.clickable tbody tr {
    cursor: pointer;
}

table.simple.clickable tbody tr:hover {
    background: rgba(0, 0, 0, 0.03);
}

table.simple.borderless {
    border: none;
}

table.simple.borderless tbody tr td {
    border: none;
}

table.simple.borderless thead tr th {
    border: none;
}

.MuiSelect-select {
    min-height: auto !important;
}

.cell-border-top {
    border-top: 1px solid #efefef;
}

.cell-border-right {
    border-right: 1px solid #efefef;
}

.cell-border-bottom {
    border-bottom: 1px solid #efefef;
}

.tbody-cell {
    border-top: 1px solid #efefef;
    border-right: 1px solid #efefef;
}

.btn-search {
    height: 30px !important;
    margin-top: 12px;
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    font-size: .6em !important;
}

.btn-export {
    height: 30px !important;
    margin-top: 12px;
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    font-size: .75em !important;
}

.btn-view-child-report {
    cursor: pointer;
    text-decoration: underline;
    color: #5a77c9;
}